<template>
    <Accordion class="p-fluid">
        <AccordionTab :header="person.name"  v-for="(person, index) in $store.state.busines.persons" :key="index" style="border-color: blanchedalmond;">
            <div class="p-grid">
                <div class="p-grid p-col-12 p-md-12 p-lg-4">
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <strong>{{$t('Name')}}:</strong> {{person.name}}
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <strong>ID / {{$t('Passport')}}:</strong> {{person.ident_passport}}
                    </div>            
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <strong>{{$t('Nationality')}}:</strong> {{person.nationality[i18n.locale()]}}
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <strong>{{$t('Residence')}}:</strong> {{person.residence}}
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <strong>{{$t('Profession')}}:</strong> {{ person.profession }}
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <strong>{{$t('Politically exposed person')}} (PEP):</strong> {{ person.pep[i18n.locale()] }}
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <strong>{{$t('Email')}}:</strong> {{ person.email }}
                    </div>
                    <div class="p-col-12 p-md-12 p-lg-12">
                        <strong>{{$t('Telephone')}}:</strong> {{ person.telephone }}
                    </div>
                </div>    
                <div class="p-col-12 p-md-12 p-lg-4" style="padding: 1em 1em 1em 1em;">
                    <div class="p-col-12 p-md-12 p-lg-12" v-if="person.doc_passport">
                        <img style="width: 100%;" :src="url+'upload/'+person.doc_passport" @click="View(person.doc_passport)"/>
                    </div>
                </div>
                <div class="p-col-12 p-md-12 p-lg-4" style="vertical-align: bottom; padding: 1em 5em 1em 5em;">
                    <h5>{{$t('Checklist')}}</h5>
                    <div v-for="(stats, index) in person.check" :key="index" style="padding-top: 1em;">
                        <Checkbox v-model="stats.activo" :binary="true"/>
                        <label class="p-ml-2">{{ stats[i18n.locale()] }}</label>
                    </div>
                </div>
            </div>
        </AccordionTab>
	</Accordion>



</template>

<script>
//import API from "../service/API";
//var Consult = new API('Registration','Uploader');
import { useI18nPlugin } from '@unify/vuex-i18n';
import Token from "uuid-token-generator";

export default {
    props: {
		alls: {
			type: Boolean,
			default: true,
		},
		nations: {
			type: Array,
			default: null,
		},
		option: {
			type: Array,
			default: null,
		}
	},
    data() {
        return {
            size: '40vw',
            nationalitys: this.nations,
            options: this.option,
			token: null,
			url: process.env.VUE_APP_API,
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '80vw';}
        this.i18n = useI18nPlugin();
		const tokgen = new Token(256);
        this.token = tokgen.generate();
    },
    watch: {
	},
    methods: {
		View(index){
			window.open(this.url+'upload/'+index, '_blank');
		},

    },


}
</script>